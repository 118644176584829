.wrap {
  margin-top: 18px;
}

.iconWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26px;
  min-height: 26px;
  margin-top: 9px;
  position: relative;
  margin-left: auto;
}

.icon {
  padding: 1px;
  color: transparent;
  stroke-width: 12px;
  stroke: #c2a661;
}

.heartButton {
  width: 100%;
}