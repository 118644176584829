.wrap {
  margin-top: 18px;
}

.iconWrap {
  flex-direction: column;
  align-items: center;
  width: 26px;
  min-height: 26px;
  margin-top: 9px;
  margin-left: auto;
  display: flex;
  position: relative;
}

.icon {
  color: #0000;
  stroke-width: 12px;
  stroke: #c2a661;
  padding: 1px;
}

.heartButton {
  width: 100%;
}
